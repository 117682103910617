import React from "react";
import {
    Box,
    Text,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
    Container,
    Image,
    SimpleGrid
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import alterationsImage from "../../../assets/img/service/alterations-1.jpg";
import { NavLink } from "react-router-dom";
export default function AlterationPage() {
    return (
        <Container maxW={"7xl"} mt="20px" p={10} bg="white" >
            <Breadcrumb
                fontWeight="medium"
                fontSize="sm"
                spacing="8px"
                textTransform="uppercase"
                separator={<ChevronRightIcon color="gray.500" />}
            >
                <BreadcrumbItem>
                    <BreadcrumbLink color="gray.500" style={{ textDecoration: "none" }}>
                        <NavLink to="/">Home</NavLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                    <BreadcrumbLink color="gray.500" style={{ textDecoration: "none" }}>
                        <NavLink to="/services">Services</NavLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink style={{ textDecoration: "none" }}>
                        Alterations
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="20px" >
                <Heading>Alterations</Heading>
                <br />


            </Box>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 2 }}
                spacing="10px"

            >
                <Box>
                    <Image
                        src={alterationsImage}
                        height="250px"
                        width="100%"
                        objectFit="cover"
                    />
                </Box>
                <Box
                    // h="50vh auto"
                    py={{ base: "10px", md: "auto", lg: "auto" }}
                    px={{ base: "0px", md: "40px", lg: "40px" }}
                    mb="10px"

                >
                    <Text pt="5px" color="black.100" display={{ base: "block", md: "block", lg: "block" }}>
                        Our team of highly skilled tailors is dedicated to solving even your most challenging clothing dilemmas. Whether your favorite suit no longer fits or that online-purchased dress missed the mark, we're here to exceed your expectations. With our expertise and commitment to quality, we'll not only have you looking your best but feeling confident and empowered in every garment we tailor. Trust us to transform your wardrobe woes into moments of sheer delight and satisfaction.
                    </Text>
                    <Box mt="10px"></Box>
                </Box>
            </SimpleGrid >
        </Container>
    );
}
