import React from "react";

import Services from "../../../components/services.js";
export default function ServicesPage() {
    return (
        <>
            <Services />
        </>
    );
}
