import React from "react";
import ForgotComponent from "./forgot";

export default function ForgotPage() {
  return (
    <>
      <ForgotComponent />
    </>
  );
}
