import React from "react";

import Contact from "../../contact/contact";

export default function ContactPage() {
  return (
    <>
      <Contact />
    </>
  );
}
