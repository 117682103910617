import React from "react";
import { Box, Text, SimpleGrid, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";



export default function ShowroomsList(props) {
    return (
        <SimpleGrid
            columns={props.columns ? props.columns : { base: 1, md: 2, lg: 1 }}
            spacing="10px"
        >
            {props.services.map((serviceObj) => {
                return (
                    <Box
                        key={serviceObj.service}
                        h="50vh"
                        px="40px"
                        py="10px"
                        mb="10px"
                        // boxShadow="md"
                        border={"1px solid rgb(240, 240, 240)"}
                        // background={`url(${serviceObj.backgroundImage})`}
                        background={`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url(${serviceObj.backgroundImage})`}
                        // Specify background size and other styles if needed
                        backgroundSize="cover"
                        backgroundPosition="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        _hover={{ boxShadow: "md" }}

                    >
                        {/* <Link style={{ textDecoration: "none" }}> */}
                        <Text pt="5px" color="gray.100" fontSize="2.5em">{serviceObj.service}</Text>
                        <Text pt="5px" color="gray.100" display={{ base: "none", md: "block", lg: "block" }}>
                            {serviceObj.descriptionOne}
                        </Text>
                        {/* <Text pt="15px" color="gray.500">
                            {serviceObj.descriptionTwo}
                        </Text>
                        <Text pt="15px" color="gray.500">
                            {serviceObj.descriptionThree}
                        </Text> */}
                        {/* <Text color="gray.500">{serviceObj.state}</Text> */}
                        <Box mt="10px"></Box>
                        {
                            props.button && (
                                <Flex>
                                    <Link to={`/services/${serviceObj.service}`}>
                                        <Box className="buttonStyle" mt="10px" border='1px' bg="none" borderColor='gray.100'>
                                            <span>Learn More</span>
                                        </Box>
                                    </Link>
                                </Flex>
                            )
                        }
                        {/* </Link> */}
                    </Box>
                );
            })}
        </SimpleGrid >
    );
}
