import React from "react";
import {
    Box,
    Text,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
    Container,
    Grid,
    GridItem
} from "@chakra-ui/react";

import alterationsImage from "../assets/img/service/alterations-1.jpg";
import customImage from "../assets/img/service/custom-1.jpg";
import uniformsImage from "../assets/img/service/uniforms-1.jpg";

import { ChevronRightIcon } from "@chakra-ui/icons";

import { NavLink } from "react-router-dom";
import ServicesList from "./servicelist";

const services = [
    {
        service: "Alterations",
        descriptionOne: "Our team of highly skilled tailors is dedicated to solving even your most challenging clothing dilemmas. Whether your favorite suit no longer fits or that online-purchased dress missed the mark, we're here to exceed your expectations. With our expertise and commitment to quality, we'll not only have you looking your best but feeling confident and empowered in every garment we tailor. Trust us to transform your wardrobe woes into moments of sheer delight and satisfaction.",
        descriptionTwo: "",
        descriptionThree: "",
        state: "New York NY 10017",
        backgroundImage: alterationsImage
    },
    {
        service: "Custom",
        descriptionOne: "At Anaqah, we epitomize the artistry of fine dressmaking, where every stitch is a testament to our unwavering commitment to excellence. Elegance in every fold is not just a promise; it's our guiding principle. Our skilled artisans meticulously craft each garment, infusing it with unparalleled quality and sophistication, ensuring that your fashion tells your unique story.",
        descriptionTwo: "Our passion for perfection extends beyond the workshop, as we collaborate seamlessly with clients to bring their visions to life. From early morning requests to overnight deadlines, we thrive on the challenge of exceeding expectations, delivering impeccable results that elevate brands and leave lasting impressions.",
        descriptionThree: "At Anaqah, we don't just create clothing; we craft experiences, embodying the essence of elegance and finesse in every tailored masterpiece. Trust us to elevate your wardrobe and transform your presence with style, sophistication, and unparalleled craftsmanship.",
        state: "New York NY 10017",
        backgroundImage: customImage
    },
    {
        service: "Uniforms",
        descriptionOne: "At Anaqah, we recognize the pivotal role your employees play in shaping your brand's identity. With our bespoke uniform tailoring service, we ensure that each team member embodies elegance and professionalism, leaving a lasting impression on your customers.",
        descriptionTwo: "Our process begins with precision, as we meticulously measure each individual to guarantee a perfect fit. From pattern drafting to garment construction, our skilled tailors pour their expertise into every stitch, ensuring that each uniform exudes quality and refinement.",
        descriptionThree: "With a diverse range of fabric options tailored to your budgetary needs, Anaqah offers flexibility without compromising on style. Trust us to elevate your brand's image, as your employees step out in attire that reflects the essence of your business, leaving an indelible mark on every customer encounter.",
        state: "New York NY 10017",
        backgroundImage: uniformsImage
    },
];
const Showrooms = () => {
    return (
        <Container maxW={"7xl"} mt="20px" p={10} bg="white">
            <Breadcrumb
                fontWeight="medium"
                fontSize="sm"
                spacing="8px"
                textTransform="uppercase"
                separator={<ChevronRightIcon color="gray.500" />}
            >
                <BreadcrumbItem>
                    <BreadcrumbLink color="gray.500" style={{ textDecoration: "none" }}>
                        <NavLink to="/">Home</NavLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink style={{ textDecoration: "none" }}>
                        Services
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
            <Box mt="20px">
                <Heading>Services</Heading>
                {/* <Text mt="20px">By Appointment Only</Text> */}
                {/* <Text mt="10px">
                    lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do lorem
                    ipsum dolor sit amet, consectetur adipiscing elit, sed do lorem ipsum
                    dolor sit amet, consectetur adipiscing elit, sed do lorem ipsum dolor
                    sit amet, consectetur adipiscing elit, sed do lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do
                </Text> */}
                <br />

                <ServicesList services={services} button={false} />
            </Box>
        </Container>
    );
};

export default Showrooms;
