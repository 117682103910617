import React from "react";

import Showrooms from "../../../components/showrooms.js";
export default function ShowroomsPage() {
  return (
    <>
      <Showrooms />
    </>
  );
}
