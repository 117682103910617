import React from "react";

import Blogs from "../../blog/blog";
export default function BlogPage() {
  return (
    <>
      <Blogs />
    </>
  );
}
